import { CloseOutlined, InfoOutlined } from '@mui/icons-material';
import {
  BaseSelectProps,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlProps,
  FormHelperText,
  IconButton,
  inputClasses,
  InputProps,
  ListItemText,
  ListSubheader,
  MenuItem,
  Input as MuiInput,
  OutlinedSelectProps,
  Select,
  Stack,
  styled
} from '@mui/material';
import { useCamelCase } from '@vestwell-frontend/hooks';

import { camelCase, isObject } from 'lodash';
import {
  forwardRef,
  ReactNode,
  useCallback,
  useId,
  useMemo,
  useState
} from 'react';
import { useUpdateEffect } from 'react-use';
import stringify from 'safe-stable-stringify';

import { PLACEHOLDER } from '../consts';
import { FormFieldLabel, FormFieldLabelProps } from './form/FormFieldLabel';
import { SelectChevron } from './SelectChevron';
import { Text } from './Text';

const parseJson = json => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return json;
  }
};

const StyledCloseBtn = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1.25)
}));

const Input = styled(MuiInput, {
  shouldForwardProp: prop => prop !== 'width'
})<InputProps & { multiple?: boolean; width?: number }>(
  ({ fullWidth, multiple, theme, width, value }) => ({
    [`& .${inputClasses.input}`]: {
      alignItems: 'center',
      color:
        (Array.isArray(value) && !value?.length) || !value
          ? theme.palette.grey400.main
          : undefined,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      whiteSpace: multiple ? 'nowrap' : 'normal !important'
    },
    width: fullWidth
      ? undefined
      : width
        ? theme.spacing(width)
        : theme.spacing(56)
  })
);

const StyledFormHelperText = styled(FormHelperText)(() => ({
  marginLeft: 0,
  marginRight: 0
}));

Input.displayName = 'Input';

type MuiOutlinedSelectProps = OutlinedSelectProps & BaseSelectProps;

export type DropdownProps = Partial<FormFieldLabelProps> &
  Omit<MuiOutlinedSelectProps, 'onChange' | 'onSelect'> & {
    error?: FormControlProps['error'];
    errorMessage?: ReactNode;
    formField?: boolean;
    hideError?: boolean;
    loading?: boolean;
    noFocusStyle?: boolean;
    onChange?: (value: MuiOutlinedSelectProps['value']) => void;
    onSelect?(value: MuiOutlinedSelectProps['value'], name: string): void;
    options: {
      label: string;
      value: string | number | Record<string, unknown>;
      isSubheader?: boolean;
    }[];
    width?: number;
  };

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex'
}));

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.grey900.main,
  color: theme.palette.grey100.main,
  fontSize: theme.spacing(3.5),
  fontWeight: 700
}));

export const Dropdown = forwardRef<HTMLInputElement, DropdownProps>(
  (
    {
      details,
      disabled,
      error,
      errorMessage,
      formField,
      label,
      loading,
      helpModal,
      hideLabel,
      hideError,
      info,
      noFocusStyle,
      options: defaultOptions,
      onChange,
      onSelect,
      width,
      ...props
    },
    ref
  ) => {
    const detailsId = useId();
    const disabledId = useId();
    const helpModalId = useId();
    const errorId = useId();
    const infoId = useId();
    const labelId = useId();

    const describedBy =
      `${disabled ? `${disabledId} ` : ''}${info ? `${infoId} ` : ''}${
        helpModal ? `${helpModalId} ` : ''
      }${details ? `${detailsId} ` : ''}${
        errorMessage ? `${errorId} ` : ''
      }`.trimEnd() || undefined;

    const testIdName = useCamelCase(props.name);

    const hasObjectValues = useMemo(() => {
      return defaultOptions.some(o => isObject(o.value));
    }, [defaultOptions]);

    const [value, setValue] = useState<DropdownProps['value']>(
      hasObjectValues
        ? props.multiple
          ? (props.value || props.defaultValue || ([] as any)).map(item =>
              stringify(item)
            )
          : stringify(props.value || props.defaultValue || '')
        : props.value || props.defaultValue || (props.multiple ? [] : '')
    );

    const onChangeValue = useCallback<MuiOutlinedSelectProps['onChange']>(
      event => {
        setValue(event.target.value);
      },
      []
    );

    const options = useMemo<
      { label: string; value: string | number; isSubheader?: boolean }[]
    >(() => {
      return defaultOptions.map(o => ({
        ...(o.isSubheader ? { isSubheader: o.isSubheader } : {}),
        label: o.label,
        value: (hasObjectValues ? stringify(o.value as any) : o.value) as
          | string
          | number
      }));
    }, [hasObjectValues, defaultOptions]);

    const optionsHash = useMemo(() => {
      return options.reduce(
        (acc, o) => ({
          ...acc,
          [o.value]: o.label
        }),
        {}
      );
    }, [options]);

    const renderValue = useCallback<MuiOutlinedSelectProps['renderValue']>(
      selected => {
        if (loading) {
          return (
            <CircularProgress
              color='primary'
              disableShrink
              size={16}
              variant='indeterminate'
            />
          );
        }

        if ((Array.isArray(selected) && !selected.length) || !selected) {
          return props.placeholder
            ? props.placeholder
            : options?.length
              ? 'Select'
              : PLACEHOLDER;
        }

        if (Array.isArray(selected)) {
          return selected.map(item => optionsHash[item]).join(', ');
        }

        return optionsHash[selected as any];
      },
      [loading, optionsHash, options?.length]
    );

    const onClearAll = useCallback(() => {
      setValue([]);
    }, []);

    const slotProps = useMemo<DropdownProps['slotProps']>(
      () => ({
        listbox: {
          'data-component': 'dropdownListbox',
          'data-testid': testIdName
        },
        popper: {
          'data-component': 'dropdownPopper',
          'data-testid': testIdName
        },
        root: {
          'data-component': 'dropdownRoot',
          'data-testid': testIdName
        },
        ...props.slotProps
      }),
      [props.slotProps]
    );

    useUpdateEffect(() => {
      const formattedValue = hasObjectValues
        ? props.multiple
          ? (value as any).map(item => parseJson(item))
          : parseJson(value)
        : value;

      if (onSelect) {
        onSelect(formattedValue, props.name);
      }

      if (onChange) {
        onChange(formattedValue);
      }
    }, [value]);

    useUpdateEffect(() => {
      const newValue =
        hasObjectValues && props.value && isObject(props.value)
          ? stringify(props.value)
          : props.value;

      const oldValue =
        hasObjectValues && value && isObject(value) ? stringify(value) : value;

      if (oldValue !== newValue) {
        setValue(() => newValue);
      }
    }, [props.value]);

    return (
      <StyledFormControl
        data-component='dropdown'
        data-testid={testIdName}
        error={error}
        fullWidth={props.fullWidth}
        required={!!props.required}>
        <FormFieldLabel
          data-component='dropdownLabel'
          details={details}
          detailsId={detailsId}
          disabled={!!disabled}
          disabledId={disabledId}
          helpModal={helpModal}
          helpModalId={helpModalId}
          hideLabel={hideLabel}
          info={info}
          infoId={infoId}
          label={label}
          labelId={labelId}
          name={props.name}
          required={!!props.required}
        />
        <Select
          {...props}
          MenuProps={{
            sx: theme => ({
              '& .MuiMenuItem-root': {
                whiteSpace: 'normal'
              },
              maxHeight: theme.spacing(82),
              width: props.fullWidth
                ? undefined
                : width
                  ? theme.spacing(width)
                  : theme.spacing(56)
            })
          }}
          SelectDisplayProps={{
            'aria-invalid': !!error,
            'aria-required': !!props.required
          }}
          aria-describedby={describedBy}
          disabled={!!disabled || !!loading}
          displayEmpty
          endAdornment={
            Array.isArray(value) &&
            value.length > 0 && (
              <StyledCloseBtn
                aria-label='Clear all selected values'
                data-testid='clearAll'
                onClick={onClearAll}
                size='small'>
                <CloseOutlined />
              </StyledCloseBtn>
            )
          }
          input={
            <Input
              data-component='dropdownInputContainer'
              data-no-focus-style={noFocusStyle}
              data-testid={testIdName}
              disabled={!!disabled || !!loading}
              fullWidth={props.fullWidth}
              multiple={props.multiple}
              required={!!props.required}
              width={width}
            />
          }
          inputProps={{
            IconComponent: props =>
              Array.isArray(value) && value.length ? undefined : (
                <SelectChevron {...props} />
              ),
            'data-component': 'dropdownInput',
            'data-testid': testIdName
          }}
          inputRef={ref}
          labelId={labelId}
          notched={undefined} // otherwise we get "notched is a boolean should be string" runtime error
          onChange={onChangeValue}
          renderValue={renderValue}
          required={!!props.required}
          slotProps={slotProps}
          value={value}>
          {options?.length ? (
            options.map(option =>
              option.isSubheader ? (
                <StyledListSubheader key={option.label}>
                  {option.label}
                </StyledListSubheader>
              ) : (
                <MenuItem
                  data-component='dropdownOption'
                  data-testid={camelCase(option.label)}
                  disableRipple
                  key={option.label}
                  value={option.value}>
                  {props.multiple ? (
                    <Stack alignItems='center' direction='row'>
                      <Checkbox
                        checked={(value as [string | number]).includes(
                          option.value
                        )}
                      />
                      <ListItemText
                        primary={option.label}
                        primaryTypographyProps={{
                          sx: { marginBottom: 0 }
                        }}
                      />
                    </Stack>
                  ) : (
                    option.label
                  )}
                </MenuItem>
              )
            )
          ) : (
            <Text
              data-component='dropdownNoOptions'
              data-testid={camelCase('No options')}
              margin={2}>
              No options
            </Text>
          )}
        </Select>
        {formField && (
          <StyledFormHelperText
            aria-atomic='true'
            data-component='dropdownError'
            data-testid={testIdName}
            id={errorId}
            role='alert'>
            {!hideError && errorMessage ? (
              <Stack alignItems='center' direction='row' gap={1}>
                <InfoOutlined color='error' fontSize='small' />
                {!!label && <span className='sr-only'>Error for {label}:</span>}
                {errorMessage}
              </Stack>
            ) : (
              ' '
            )}
          </StyledFormHelperText>
        )}
      </StyledFormControl>
    );
  }
);

Dropdown.displayName = 'Dropdown';
