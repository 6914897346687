import { LockOutlined } from '@mui/icons-material';
import {
  FormControlLabelProps,
  InputLabel,
  SelectProps,
  Stack,
  styled
} from '@mui/material';
import { useCamelCase } from '@vestwell-frontend/hooks';

import { FC, ReactNode } from 'react';
import { useToggle } from 'react-use';

import { IconTooltip } from '../IconTooltip';
import { Modal } from '../Modal';
import { ModalBody } from '../ModalBody';

const Details = styled('p')(({ theme }) => ({
  color: theme.palette.grey300.main,
  fontSize: '.875rem',
  lineHeight: 1.625,
  maxWidth: '39.9375em'
}));

export type FormFieldLabelProps = {
  'data-component': string;
  info?: ReactNode;
  infoId?: string;
  details?: ReactNode;
  detailsId?: string;
  disabled?: SelectProps['disabled'];
  disabledId?: string;
  label: FormControlLabelProps['label'];
  labelId?: SelectProps['labelId'];
  helpModal?: ReactNode;
  helpModalId?: string;
  hideLabel?: boolean;
  hideLocked?: boolean;
  name: SelectProps['name'];
  required?: SelectProps['required'];
};

export const FormFieldLabel: FC<FormFieldLabelProps> = props => {
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  const testIdName = useCamelCase(props.name);

  return !props.label || props.hideLabel ? (
    <label
      className='sr-only'
      data-component={props['data-component']}
      data-testid={testIdName}
      id={props.labelId}>
      {props.label || props.name}
    </label>
  ) : (
    <Stack>
      <Stack alignItems='center' direction='row'>
        <InputLabel
          data-component={props['data-component']}
          data-testid={testIdName}
          disabled={props.disabled}
          id={props.labelId}
          required={!!props.required}
          shrink={false}>
          {props.label}
        </InputLabel>
        {props.disabled && !props.hideLocked && (
          <IconTooltip
            aria-label='Disabled'
            data-component={`${props['data-component']}Disabled`}
            data-testid={testIdName}
            id={props.disabledId}
            trigger={<LockOutlined fontSize='small' />}
          />
        )}
        {props.info && (
          <IconTooltip
            aria-label={`More Info On ${props.label}`}
            data-component={`${props['data-component']}Info`}
            data-testid={testIdName}
            id={props.infoId}>
            {props.info}
          </IconTooltip>
        )}
        {props.helpModal && (
          <>
            <IconTooltip
              aria-label={`Info for ${props.label}`}
              data-component={`${props['data-component']}InfoModal`}
              data-testid={testIdName}
              id={props.helpModalId}
              onClick={toggleIsModalOpen}
            />
            <Modal
              aria-labelledby={props.helpModalId}
              closeButton
              isOpen={isModalOpen}
              onRequestClose={toggleIsModalOpen}>
              <ModalBody>{props.helpModal}</ModalBody>
            </Modal>
          </>
        )}
      </Stack>
      {props.details && (
        <Details
          data-component={`${props['data-component']}Details`}
          data-testid={testIdName}
          id={props.detailsId}>
          {props.details}
        </Details>
      )}
    </Stack>
  );
};

FormFieldLabel.displayName = 'FormFieldLabel';
