import { Grid2, styled } from '@mui/material';
import { useCamelCase } from '@vestwell-frontend/hooks';

import { FC, ReactNode } from 'react';

import { PLACEHOLDER } from '../../consts';
import { Link } from '../Link';
import { Loading } from '../Loading';
import { Text } from '../Text';

export type VerticalAttributeProps = {
  'aria-level'?: number;
  caption?: ReactNode;
  children?: ReactNode;
  'data-testid'?: string;
  isLoading?: boolean;
  label: ReactNode;
  linkTo?: string;
  role?: string;
  tooltip?: ReactNode;
  value?: ReactNode;
  variant?: 'medium' | 'large';
};

const StyledLink = styled(Link, { name: 'VerticalAttributeLink' })(
  ({ theme, ...props }) => ({
    ...(props['data-variant'] === 'large' && {
      fontSize: theme.spacing(4.5),
      fontWeight: theme.typography.fontWeightBold
    }),
    ...(props['data-variant'] === 'medium' && {
      fontSize: theme.spacing(4),
      fontWeight: theme.typography.fontWeightBold
    })
  })
);

const StyledText = styled('div', { name: 'VerticalAttributeText' })(
  ({ theme, ...props }) => ({
    color: theme.palette.grey100.main,
    fontSize:
      props['data-variant'] === 'medium'
        ? theme.spacing(4)
        : theme.spacing(4.5),
    fontWeight: theme.typography.fontWeightBold
  })
);

const StyledVerticalAttribute = styled('div', { name: 'VerticalAttribute' })(
  ({ theme }) => ({
    marginBottom: theme.spacing(8),
    textAlign: 'left'
  })
);

export const VerticalAttribute: FC<VerticalAttributeProps> = ({
  variant = 'medium',
  ...props
}) => {
  const testId = useCamelCase(
    props['data-testid'] || (typeof props.label === 'string' ? props.label : '')
  );

  return (
    <StyledVerticalAttribute
      {...props}
      data-component='verticalAttribute'
      data-testid={testId}>
      <Grid2 alignItems='end' container>
        <Grid2>
          <Text
            aria-level={props['aria-level']}
            color='grey300'
            data-component='verticalAttributeLabel'
            data-testid={testId}
            mb={0}
            role={props.role}>
            {props.label}
          </Text>
        </Grid2>
        <Grid2>{props.tooltip}</Grid2>
      </Grid2>
      {props.isLoading ? (
        <Loading
          backgroundColor='grey800'
          data-testid='VerticalAttributeLoading'
          width={80}
        />
      ) : (
        <>
          {props.linkTo ? (
            <StyledLink
              data-component='verticalAttributeLink'
              data-testid={testId}
              data-variant={variant}
              to={props.linkTo}>
              {props.children || props.value || PLACEHOLDER}
            </StyledLink>
          ) : (
            <StyledText
              data-component='verticalAttributeText'
              data-testid={testId}
              data-variant={variant}>
              {props.children || props.value || PLACEHOLDER}
            </StyledText>
          )}
          {props.caption && (
            <Text
              color='grey300'
              data-component='verticalAttributeCaption'
              data-testid={testId}
              variant='i1'>
              {props.caption}
            </Text>
          )}
        </>
      )}
    </StyledVerticalAttribute>
  );
};
