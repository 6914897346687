import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';

import axios from 'axios';
import Cookies from 'js-cookie';
import { MouseEvent, useCallback, useState } from 'react';

import { useNotifications } from './useNotifications';
import { useSecureStorage } from './useSecureStorage';

const blobToBase64DataUrl = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

function triggerFileDownload(data: string, name: string) {
  const link = document.createElement('a');
  link.href = data;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const useDownload = (
  pathname: string,
  avoidPreview: boolean = false
) => {
  const [fileUri, setFileUri] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileToken] = useSecureStorage('token');

  const notify = useNotifications();

  return useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();

      // If already loading or data exists, prevent further action
      if (isLoading) {
        notify.info('Document is already being downloaded.');
        return;
      }

      if (fileUri) {
        if (!mobileToken) {
          if (avoidPreview) {
            triggerFileDownload(fileUri, fileName); // For web, download existing document
          } else {
            window.open(fileUri, '_blank'); // For web, open existing document
          }
        } else {
          try {
            // For mobile, re-open the file if already downloaded
            await FileOpener.open({
              filePath: fileUri,
              openWithDefault: true
            });
          } catch (error) {
            console.error('Error re-opening file on mobile', error);
            notify.error('Error re-opening file');
          }
        }
        return;
      }

      let token;
      const webToken = Cookies.get('token');
      if (webToken) {
        token = webToken;
      } else if (mobileToken) {
        const parsedToken = JSON.parse(mobileToken);
        if (parsedToken?.access_token && parsedToken?.token_type) {
          token = `${parsedToken.token_type} ${parsedToken.access_token}`;
        }
      }

      notify.info(
        'The document is being prepared for download. This may take a few seconds.'
      );
      setIsLoading(true);

      try {
        const response = await axios.get(pathname, {
          headers: {
            Authorization: token
          },
          responseType: 'blob'
        });

        const blob = response.data;
        const contentDisposition = response.headers['content-disposition'];
        const downloadedFileName = contentDisposition
          ? contentDisposition
              .split('filename=')[1]
              ?.split(';')[0]
              ?.replace(/"/g, '')
          : `${new Date().getTime()}.${getFileExtension(blob.type)}`;
        setFileName(downloadedFileName);

        if (!mobileToken) {
          if (avoidPreview) {
            // Web: just download document
            const dataUrl = await blobToBase64DataUrl(blob);
            setFileUri(dataUrl);
            triggerFileDownload(dataUrl, downloadedFileName);
          } else {
            // Web: Open the blob as a new URL
            const blobUrl = window.URL.createObjectURL(blob);
            setFileUri(blobUrl);
            window.open(blobUrl, '_blank'); // open in new tab
          }
        } else {
          // Mobile: Save file and open with default app
          const dataUrl = await blobToBase64DataUrl(blob);

          const savedFile = await Filesystem.writeFile({
            data: dataUrl,
            directory: Directory.Documents,
            path: downloadedFileName,
            recursive: true
          });

          setFileUri(savedFile.uri); // Store file URI for reuse

          await FileOpener.open({
            filePath: savedFile.uri,
            openWithDefault: true
          });
        }
      } catch (error) {
        console.error('Failed to fetch document', error);
        notify.error('Failed to fetch document');
      } finally {
        setIsLoading(false);
      }
    },
    [fileUri, isLoading, pathname, avoidPreview, mobileToken]
  );
};

const getFileExtension = (mimeType: string) => {
  switch (mimeType) {
    case 'application/pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    default:
      return mimeType.split('/')[1];
  }
};
